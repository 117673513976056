<template>
  <div class="online-users">
    <div
      v-for="user in pokerSession.users"
      :key="user.uuid"
      class="user"
      @click="findUser(user.uuid)"
    >
      <div class="user-avatar">
        <span>{{ user.firstName }}</span>
        <span>{{ user.lastName }}</span>
        <el-badge
          v-if="pokerSession.exists()"
          class="mark"
          :value="(user.options.pokerSessionStatus === 'PENDING') ? '?'
            : (user.options.pokerSessionStatus === 'JOINED') ? '✓'
              : '✕'"
          :type="(user.options.pokerSessionStatus === 'PENDING') ? 'danger'
            : (user.options.pokerSessionStatus === 'JOINED') ? 'success'
              : 'warning'"
        />
      </div>
    </div>
    <el-popover
      v-model="visible"
      placement="right"
      visible-arrow="true"
      width="170"
    >
      <p>Are you sure want to delete {{ removedUser.name }}?</p>
      <div
        style="text-align: right; margin: 0"
      >
        <el-button
          size="mini"
          type="text"
          @click="cancel"
        >
          Cancel
        </el-button>
        <el-button
          type="primary"
          size="mini"
          @click="remove"
        >
          Delete
        </el-button>
      </div>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: 'OnlineMembers',
  props: {
    pokerSession: {
      type: Object,
      require: false,
      default: () => null,
    },
  },
  data() {
    return {
      removedUser: {},
      visible: false,
    };
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
      // this.$confirm('Are you sure to close this dialog?')
      //   .then(_ => {
      //     done();
      //   })
      //   .catch(_ => {});
    },
    findUser(uuid) {
      this.removedUser = this.pokerSession.users.find(el => el.uuid === uuid);
      this.visible = !this.visible;
    },
    cancel() {
      this.removedUser = {};
      this.visible = !this.visible;
    },
    remove() {
      this.$emit('remove-user', this.removedUser);
      this.removedUser = {};
      this.visible = !this.visible;
    },
  },
};
</script>

<style scoped>
    .online-users {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .user-avatar {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      background-color: #409eff;
      color: #eeeeee;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-top: 15px;
      align-items: center;
      justify-content: center;
      font-size: 6px;
      position: relative;
    }
  .mark {
    position: absolute;
    padding-right: -40px;
    padding-top: 35px;
    padding-left: 30px;
  }
</style>
